import React, { useEffect } from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";

const OfferForm = (props) => {

    // Called on mount
    useEffect(() => {
        initializeHubspotForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function initializeHubspotForm() {
        if ('hbspt' in window) {
            window.hbspt.forms.create({
                region: "eu1",
                portalId: "26048753",
                formId: "3f5b78b1-c8ed-44a0-86f9-97f9ce9579b3",
                target: "#" + props.formID,
                onFormSubmit: function ($form) {
                    if (window.consentGiven === true) {
                        window.lintrk('track', { conversion_id: 11152436 });
                    }
                }
            });
        } else {
            setTimeout(initializeHubspotForm, 500)
        }
    }

    return (
        <section className="overflow-y-scroll">
            <div className="h-2 lg:h-12" id="offer-form-anchor"></div>
            <div className="flex w-full justify-center px-2 md:px-4 pb-2 lg:pb-0">
                <div className="grid grid-cols-1 lg:grid-cols-2 bg-white">

                    <div className="flex flex-col justify-center gap-16 lg:max-w-[500px] pl-12 pr-16 order-2 lg:order-1 pb-8 lg:pb-0">
                        <div className="flex">
                            <div className="text-3xl font-semibold text-[#6A00FF]">1</div>
                            <div className="pt-[8px] pl-4">
                                <div className="text-base font-semibold text-gray-800"><FormattedMessage id="offer_form_steps_1_heading" /></div>
                                <div className="text-base text-gray-600 pt-2"><FormattedMessage id="offer_form_steps_1_subheading" /></div>

                            </div>
                        </div>
                        <div className="flex">
                            <div className="text-3xl font-semibold text-[#6A00FF]">2</div>
                            <div className="pt-[8px] pl-4">
                                <div className="text-base font-semibold text-gray-800"><FormattedMessage id="offer_form_steps_2_heading" /></div>
                                <div className="text-base text-gray-600 pt-2"><FormattedMessage id="offer_form_steps_2_subheading" /></div>

                            </div>
                        </div>
                        <div className="flex">
                            <div className="text-3xl font-semibold text-[#6A00FF]">3</div>
                            <div className="pt-[8px] pl-4">
                                <div className="text-base font-semibold text-gray-800"><FormattedMessage id="offer_form_steps_3_heading" /></div>
                                <div className="text-base text-gray-600 pt-2"><FormattedMessage id="offer_form_steps_3_subheading" /></div>

                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-end w-full sm:min-w-[500px] order-1 lg:order-2">
                        <div className="w-full h-72 flex justify-end pt-16 pr-16" id="offerTextWrapper">
                            <p className="text-white text-[2.6rem] w-[330px] text-right" style={{ lineHeight: 1.3 }}><FormattedMessage id="offer_form_graphic_text" /></p>
                        </div>
                        <div id={props.formID} className="min-h-[456px] w-full py-4 px-4 max-w-[600px]"></div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OfferForm;
